import React from "react";
import carouselCover from "../../sections/CarouselSection/images/cover.jpg";
import carouselRunner from "../../sections/CarouselSection/images/screenshot_challenging_endless_runner.png";
import carouselFly from "../../sections/CarouselSection/images/screenshot_fly.png";
import carouselShare from "../../sections/CarouselSection/images/screenshot_share_replays.png";
import carouselBonuses from "../../sections/CarouselSection/images/screenshot_bonuses.png";
import carouselCustomize from "../../sections/CarouselSection/images/screenshot_customize_your_character.png";

import { CarouselSection } from '../../sections/CarouselSection/CarouselSection';
import { FirstSection } from '../../sections/FirstSection/FirstSection';
import { FirstQuotesSection } from '../../sections/FirstQuotesSection/FirstQuotesSection';
import { TrailerSection } from '../../sections/TrailerSection/TralierSection';
import { FollowSection } from '../../sections/FollowSection/FollowSection';
import { AboutUsSection } from '../../sections/AboutUsSection/AboutUsSection';
import { SecondQuotesSection } from '../../sections/SecondQuotesSection/SecondQuotesSection';

import { Header } from '../../header/Header';
import Color from "color";
import { colorsMap } from '../../colorsMap';

export function HomePage(props: {color: Color}) {
    let {color} = props;
    return <div>
        <Header color={color} />
            <FirstSection color={color} />
            <CarouselSection images={
              [
                carouselCover, 
                carouselRunner,
                carouselFly,
                carouselShare,
                carouselBonuses,
                carouselCustomize
              ]
              } color={color} />
              <FirstQuotesSection color={colorsMap.red} />
              <TrailerSection color={colorsMap.green} />
              <FollowSection color={colorsMap.purple} />
              <SecondQuotesSection color={colorsMap.yellow} />
              <AboutUsSection color={colorsMap.purple} />
    </div>
}