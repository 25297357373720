import React from 'react';
import { getDarkerColor } from '../../utils/color-helpers';
import Color from 'color';
import "./ColorSection.css";

export function ColorSection(props: {children?: React.ReactElement, color: Color, full?: boolean, className?: string}) {
  return (
    <div className={`Color-Section${props.full ? " full" : ""}${props.className ? (" "+props.className) : ""}`}
         style={{background: props.color.hsl().string(), color: "#fff", width: "100%"}}>
      {props.children}
    </div>
  );
}
