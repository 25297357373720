
import React from 'react';
import { ColorSection } from '../../common/section/ColorSection';
import Color from 'color';
import sunfuryImg from "../FollowSection/sunfury.png";
import { SectionContent } from '../../common/section/SectionContent';
import { DeveloperData } from './DeveloperData';
import { colorsMap } from '../../colorsMap';
let developers: {name: string, color: Color, nickname?: string, url?: string, work: React.ReactNode}[] = [
    {"name": "Rom Haviv", "nickname": "HealTheIll", "work" : <span>Game&nbsp;Programmer, Game&nbsp;Art</span>, "color": colorsMap.yellow, "url" : "https://healtheill.deviantart.com/"},
    {"name": "Tom Bogin", "work": <span>Game&nbsp;Programmer, Web&nbsp;Developer</span>, "color" : colorsMap.green},
    {"name": "Artur Umarov", "work": "Mental Support", "color" : colorsMap.purple},
    {"name": "Asaf Nakar", "work": "Music Composer", "color" : colorsMap.red},
]
export function AboutUsSection(props: {color: Color}) {
    return <ColorSection color={props.color} full={true}>
        <SectionContent title={"ABOUT US - SUNFURY"}>
            <div className="imgContainer">
                <a href="http://sunfury.team" target="_blank"> 
                    <img src={sunfuryImg} alt={"SUNFURY Website"} />
                </a>
                <p className="LongParagraph">
                    We are developing games since we were kids,<br/>
                    Always strive to go beyond our limits and work hard,<br/>
                    yet, to enjoy and have fun at the same time<br/>
                </p>
                <div className="Developers">
                    {developers.map(dev => <DeveloperData {...dev} />)}
                </div>
                <p>
                    <h3 className="Title">Support and Contact</h3>
                    <p className="LongParagraph">
                        <a href="mailto:sunfuryteam@gmail.com">sunfuryteam@gmail.com</a>
                    </p>
                </p>
                <p>
                    <h3 className="Title">Monetization Permission</h3>
                    <p className="LongParagraph">
                        SUNFURY allows for the contents of StairJump to be published through video&nbsp;broadcasting&nbsp;services&nbsp;for&nbsp;any commercial&nbsp;or&nbsp;non&nbsp;-&nbsp;commercial&nbsp;purposes.<br/>
                        Monetization of videos created containing assets from StairJump<br/>
                        is legally & explicitly allowed by SUNFURY.
                    </p>
                </p>
                <p>
                    <h3 className="Title">Our Life Phrase</h3>
                    <p className="LongParagraph">
                        "Give a man a stair, he will jump once, <br/>
                        Give him StairJump and he will jump for his entire life."<br/>
                    </p>
                </p>
                <p>
                    <h3 className="Title">Our Goal</h3>
                    <p className="LongParagraph">Making fun good games.</p>
                </p>
                <p>
                    <h3 className="Title">Birthdate</h3>
                    <p className="LongParagraph">3.5.2014</p>
                </p>
                <p>
                    <h3 className="Title">Kids after StairJump</h3>
                    <p className="LongParagraph">
                        Currently pregnant with 4 kids, <br /> our first kid already come out to google play and app store!<br/>
                    </p>
                </p>
            </div>
        </SectionContent>
    </ColorSection>
}