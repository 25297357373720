import React from 'react';
import Color from 'color';
import appstore from "./appstore.png";
import googleplay from "./googleplay.png";
import "./DownloadButtons.css";

let googlePlayUrl = "https://play.google.com/store/apps/details?id=com.Stairjump.StairsJump";
let itunesUrl = "https://apps.apple.com/us/app/id1302926417";

export function DownloadButtons() {
  return (
    <div className="DownloadButtons">
      <a href={googlePlayUrl}>
          <img src={googleplay} alt ="download google play" />
      </a>
      <a href={itunesUrl}>
          <img src={appstore} alt ="download app store" />
        </a>
    </div>
  );
}
