import React from 'react';
import Color from 'color';
import { ColorSection } from '../../common/section/ColorSection';

import Slider from "react-slick";

import "./CarouselSection.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export function CarouselSection(props: {images: string[], color: Color}) {
    let settings = {
        focusOnSelect:true,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 3000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    centerMode: false,
                    variableWidth: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
  return (
    <ColorSection color={props.color}>
        <div style={{maxHeight: "450px"}}>
            <Slider {...settings} >
                {props.images.map((img, idx) => <div key={idx} className="custom-slider-container">
                        <div className="slider-background-img" style={{backgroundPosition: "center", backgroundImage: `url('${img}')`}}></div>
                    </div>)}
            </Slider>
        </div>
    </ColorSection>
  );
}
