import React from 'react';
import "./TrailerSection.css";
import { ColorSection } from '../../common/section/ColorSection';
import Color from 'color';
import { SectionContent } from '../../common/section/SectionContent';
import Player from "react-lazy-youtube";
export function TrailerSection(props: {color: Color}) {
    return <ColorSection color={props.color}>
        <SectionContent className="TrailerContentSection" title={"WATCH TRAILER"}>
            <div className="videoWrapper" >
                <Player imageSize="hqdefault" id={"2yCLsn-gwPA"} />
            </div>
        </SectionContent>
    </ColorSection>
}