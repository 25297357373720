import React from 'react';
import "./SectionContent.css";

export function SectionContent(props: {children: React.ReactElement, title?: React.ReactNode, className?: string, space?: boolean}) {
  return (
    <div className={`SectionContent${props.className ? (" "+props.className) : ""}${props.space === undefined || props.space === true ? (" space") : ""}`} >
      {props.title ? <div className="SectionTitle"><h2>{props.title}</h2></div> : null}
      {props.children}
    </div>
  );
}
