
import React from 'react';
import { ColorSection } from '../../common/section/ColorSection';
import Color from 'color';
import "./FirstSection.css"
import { SectionContent } from '../../common/section/SectionContent';
export function FirstSection(props: {color: Color}) {
    return <ColorSection color={props.color}>
            <SectionContent className="FirstSectionContent">
                <div>
                    <div className="StairJumpText">StairJump. </div>
                    <div className="StairJumpDescriptionText">Fun simple arcade game which is all about&nbsp;<b>stairs</b>&nbsp;and&nbsp;<b>jumps</b>.</div>
                </div>
            </SectionContent>
    </ColorSection>
}