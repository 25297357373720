import React from 'react';
import {Player} from "./Player/Player";
import { SectionContent } from '../common/section/SectionContent';
import "./Header.css"
import Color from "color";
import { getDarkerColor } from '../utils/color-helpers';
import { DownloadButtons } from '../common/download/DownloadButtons';

export class Header extends React.PureComponent<{color: Color}, {color: string, scrolling: boolean}> {
    ref: React.RefObject<HTMLDivElement>;
    interval: NodeJS.Timeout | undefined;
    constructor(props: any) {
        super(props);
        this.state = {
            scrolling: false,
            color: props.color
        }
        this.ref = React.createRef();
    }
    
    componentDidMount() {
        let lastScroll = window.scrollY;
        let scrolls = 0;
        this.interval = setInterval(() => {
            let color = this.getCurrentColor();
            let scroll = window.scrollY;
            let scrolling = Math.abs(scroll-lastScroll) > 0;
            if(!scrolling) {
                scrolls--;
            } else {
                scrolls = 10;
            }
            if(scrolls > 0) {
                scrolling = true;
            }
            this.setState({color, scrolling});
            lastScroll = scroll;
        }, 1000/60);
    }

    componentWillUnmount() {
        if(this.interval) {
            clearInterval(this.interval);
        }
    }

    getCurrentColor(): string {
        if(this.ref.current) {
            let headerPos = this.ref.current.getBoundingClientRect();
            let color: string = "";
            let prevColor: string = "";
            let sections = document.querySelectorAll(".Color-Section");
            for(let i=0; i<sections.length; i++)
            {
                let section = sections[i];
                prevColor = color;
                color = (section as HTMLElement).style.background;
                let pos = section.getBoundingClientRect();
                if(pos.top > headerPos.bottom) {
                    if(prevColor) {
                        color = prevColor;
                    }
                    break;
                }
            }
            return color;
        }
        return "";
    }
    

    render() {
        let props = this.props;
        return (
            <div>
                <div ref={this.ref} className="Header" style={{background: getDarkerColor(new Color(this.state.color)).hsl().string(), color: new Color(this.state.color).hsl().string()}}>
                    <SectionContent space={false}>
                        <div>
                            <div className="Header-Downloads">
                                <DownloadButtons />
                            </div>
                            <div className="Header-Title">
                                <Player jumping={this.state.scrolling} 
                                        style={{display: "inline-block"}}  
                                        />
                                <h1>
                                    StairJump
                                </h1>
                            </div>
                        </div>
                    </SectionContent>
                </div>
                <div className="Header-PlacerHolder"></div>
            </div>
          );
    }
}
