import React from 'react';
import './App.css';
import {Header} from "./header/Header";
import { getRandomColor, getDarkerColor } from './utils/color-helpers';
import Color from 'color';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams
} from "react-router-dom";
import { WatchPage, Watch } from './pages/WatchPage/WatchPage';
import { HomePage } from './pages/HomePage/HomePage';
import { ColorSection } from './common/section/ColorSection';
import { SectionContent } from './common/section/SectionContent';


function App() {
  let color = getRandomColor();

  return (
    <div style={{height: "100%", background: color.rgb().string()}}>
      <Router>
        <Switch>
          <Route path="/Gift/:watchId">
            <Header color={color} />
            <Watch isReplay={false} color={color} />
          </Route>
          <Route path="/Watch/:watchId">
            <Header color={color} />
            <Watch isReplay={true} color={color} />
          </Route>
          <Route path="/privacy">
            <div>
              <Header color={color} />
              <ColorSection color={color} full={true}>
                <SectionContent>
                  <div>
                  We respect your privacy. Here's what we store and do with your activity:
                  <br/>
                  <br/><b>Analytics</b>
                  <br/>We use google analytics and firebase analytics to learn about our users, but we don't keep any identifying detail in it, we use it to know general trends.
                  <br/>
                  <br/><b>Public Data</b>
                  <br/>Once you share the game you played, it'll be public for everyone. 
                  <br/>Your highscore is public to everyone if you logged in from to the game to a google/ios game center, or logged in to facebook.
                  <br/>
                  <br /><b>Notifications</b>
                  <br/>We store your push notification token, so we can send you a notification.
                  <br/>
                  
                  <br/><b>Facebook</b> (Only if you login to it from the game)
                  <br/>If you login to facebook, we'll use facebook to verify your identity, to backup your progress and comparing scores to your friends.
                  <br/>We won't post anything for you on facebook, we'll mainly use your name so your friends can see it, when they play the game.
                  </div>
                </SectionContent>
              </ColorSection>
            </div>
          </Route>
          <Route path="/">
              <HomePage color={color} />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
