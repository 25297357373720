import Color from 'color';
import React from "react";
import "./DeveloperData.css"

function LinkIf(props: {condition: boolean, url?: string, children: JSX.Element}): JSX.Element {
    return props.condition 
            ? <a href={props.url}>{props.children}</a> 
            : props.children;
}

export function DeveloperData(props: {name: string, color: Color, nickname?: string, url?: string, work: React.ReactNode}) {
    return <div className="Developer">
        <LinkIf condition={!!props.url} url={props.url}>
            <div className="name">
                    {props.name}
                    {props.nickname ? <span className="nickname">{props.nickname}</span> : null}
            </div>
        </LinkIf>
        <div className="work">
            {props.work}
        </div>
    </div>
}