
import React from 'react';
import { ColorSection } from '../../common/section/ColorSection';
import Color from 'color';
import { SectionContent } from '../../common/section/SectionContent';
import "./SecondQuotesSection.css"
export function SecondQuotesSection(props: {color: Color}) {
    return <ColorSection className="SecondQuotesSection" color={props.color}>
        <SectionContent>
            <div>
                <div className="quoteContainer firstQuote">
                    <span className="quoteText">It's&nbsp;kinda&nbsp;fun...</span>
                    <span className="quoteAuthor">Rom's Sister</span>
                </div>
                <div className="quoteContainer secondQuote">
                    <span className="quoteText">It's free!</span>
                    <span className="quoteAuthor">Rom's Mother</span>
                </div>
                <div className="quoteContainer thirdQuote">
                    <span className="quoteText">Just Download It.</span>
                    <span className="quoteAuthor">Rom Haviv</span>
                </div>
                <div className="clear"></div>
            </div>
        </SectionContent>
    </ColorSection>
}