import React from 'react';
import { ColorSection } from '../../common/section/ColorSection';
import Color from 'color';
import { SectionContent } from '../../common/section/SectionContent';
import { DownloadButtons } from '../../common/download/DownloadButtons';
import { colorsMap } from '../../colorsMap';
import ReactLoading from 'react-loading';

import { Instructions } from './Instructions';
import { AppCodeData, getWatchData, AuthorData, getGiftData } from '../../api';
import { useParams } from 'react-router-dom';

interface WatchPageProps {
  watchId: string | undefined;
  color: Color;
}
interface WatchPageState<T> {
  loading: boolean,
  watch?: T; //AppCodeData | AuthorData
  error: boolean;
}

export function Watch(props: {color: Color, isReplay: boolean}) {
  let { watchId } = useParams();
  return <div>
    {props.isReplay 
      ? <WatchReplayPage watchId={watchId} color={props.color} />
      : <WatchGiftPage watchId={watchId} color={props.color} />}
  </div>
}


export abstract class WatchPage<T> extends React.Component<WatchPageProps, WatchPageState<T>> {
  constructor(props: WatchPageProps) {
    super(props);
    this.state = {
      loading: true,
      watch: undefined,
      error: false
    };
  }

  componentDidMount() {
    this.fetchWatchData();
  }

  abstract getData(id: string): Promise<T | undefined>; //getWatchData(this.props.watchId)


  async fetchWatchData() {
    if(this.props.watchId) {
      this.setState({loading: true});
      try {
        let watchdata = await this.getData(this.props.watchId);
        this.setState({
          loading: false,
          watch: watchdata,
          error: false
        })
      } catch(e) {
        this.setState({
          loading: false,
          watch: undefined,
          error: true
        })
      }
    } else {
      this.setState({
        loading: false,
        watch: undefined,
        error: false
      })
    }
  }

  abstract showError(): React.ReactNode;
  abstract showNotFound(): React.ReactNode;
  abstract showWatch(watch: T): React.ReactNode;
  
  render() {
    let { watch, loading } = this.state;
    if(loading)
      return <ColorSection color={this.props.color} full={true}>
        <SectionContent>
          <div style={{width: "100px", margin: "auto", paddingTop: "150px"}}>
            <ReactLoading type={"spin"} color={"#fff"} height={100} width={100} />
          </div>
        </SectionContent>
      </ColorSection>
    else if(this.state.error) {
      return this.showError();
    }
    else if(!watch || !this.props.watchId) {
      return this.showNotFound();
    }
    return this.showWatch(watch);
  }
}

export class WatchGiftPage extends WatchPage<AppCodeData> {
  showError(): React.ReactNode {
    return <div>
        <ColorSection color={this.props.color} full={true}>
          <SectionContent>
            <div>
              <p>You're here because someone wanted to give you a StairJump gift.</p>
              <p>Unfortunately this gift can not be loaded, try again later.</p>
            </div>
          </SectionContent>
        </ColorSection>
    </div>;
  }
  showNotFound(): React.ReactNode {
    return <div>
      <ColorSection color={this.props.color} full={true}>
        <SectionContent>
          <div>
            <p>You're here because someone wanted to give you a StairJump gift.</p>
            <p>Unfortunately this gift can not be found.</p>
          </div>
        </SectionContent>
      </ColorSection>
    </div>;
  }
  showWatch(watch: AppCodeData): React.ReactNode {
    return <div>
      <ColorSection color={this.props.color}>
        <SectionContent>
          <div>
              <div style={{paddingTop: "25px"}}>
                  {watch.gifterimg ? <img src={watch.gifterimg} alt={watch.gifter} /> : null}
                  <p>Hey, you're here because {watch.gifter} wanted to share a gift with you.</p>
                  <p>To use this gift, you must download StairJump first.</p>
              </div>
              <br />
              <DownloadButtons />
          </div>
        </SectionContent>
      </ColorSection>
      
      <ColorSection color={colorsMap.red} full={true}>
        <SectionContent title={"Instructions"}>
          <div>
            <Instructions enjoy={"gift"} watchId={this.props.watchId} />
          </div>
        </SectionContent>
      </ColorSection>
    </div>;
  }

  getData(id: string): Promise<AppCodeData | undefined> {
    return getGiftData(id);
  }
}

export class WatchReplayPage extends WatchPage<AuthorData> {
  getData(id: string): Promise<AuthorData | undefined> {
    return getWatchData(id);
  } 
  

  showError(): React.ReactNode {
    return <div>
        <ColorSection color={this.props.color} full={true}>
          <SectionContent>
            <div>
              <p>You're here because someone wanted to share with you a StairJump replay.</p>
              <p>Unfortunately this replay can not be loaded, try again later.</p>
            </div>
          </SectionContent>
        </ColorSection>
    </div>;
  }
  showNotFound(): React.ReactNode {
    return <div>
      <ColorSection color={this.props.color} full={true}>
        <SectionContent>
          <div>
            <p>You're here because someone wanted to share with you a StairJump replay.</p>
            <p>Unfortunately this replay can not be found.</p>
          </div>
        </SectionContent>
      </ColorSection>
    </div>;
  }
  showWatch(watch: AuthorData): React.ReactNode {
    return <div>
      <ColorSection color={this.props.color}>
        <SectionContent>
          <div>
              <div style={{paddingTop: "25px"}}>
                  {watch.facebook_id ? <img src={`https://graph.facebook.com/${watch.facebook_id}/picture?width=64&height=64`} title={watch.author} alt={watch.author} /> : null}
                  <p>Hey, you're here because {watch.author || "someone"} wanted to share with you this replay,<br />
                  After scoring {watch.score}</p>
                  <p>To watch this replay, you must download StairJump first.</p>
              </div>
              <br />
              <DownloadButtons />
          </div>
        </SectionContent>
      </ColorSection>
      
      <ColorSection color={colorsMap.red} full={true}>
        <SectionContent title={"Instructions"}>
          <div>
            <Instructions enjoy={"replay"} watchId={this.props.watchId} />
          </div>
        </SectionContent>
      </ColorSection>
    </div>;
  }
}