import React from 'react';
import idle from "./idle.png";
import moves from "./moves.png";

export function Player(props: {style?: any, jumping: boolean}) {
  return (
    <div style={{width: "64px", height: "64px", background: "currentColor", ...props.style}}>
        <img alt="player" src={props.jumping ? moves : idle} />
    </div>
  );
}
