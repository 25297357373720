import React from 'react';
import downloadSampleImg from "./downloadsample.png";
import mainmenuWatchImg from "./mainmenuwatch.png";
import openapp from "./openapp.jpeg";
import "./Instructions.css";
import { getMobileOperatingSystem } from '../../utils/agent';

export function Instructions(props: {
  watchId: string | undefined;
  enjoy: string;
}) {
  return <div className="instructions">
    After you installed it:
    <div className="instruction">
      {getMobileOperatingSystem() === "Android" ? <div>
          <br/>
          Simply click here:
          <br/>
          <br/>
          <a target="_blank" style={{fontSize: "4em"}} href={window.location.href}>Click Here</a>
          <br/>
          <br/>
          <div>Or if it doesn't work, follow these instructions:</div>
      </div> : null}
    </div>
    <div className="instruction">
      <img title="open StairJump" alt="open StairJump" src={openapp} />
      <div>
        Open the game
        </div>
      <br />
      <br />
    </div>
    <div className="instruction">
      <img title="click WATCH" alt="click WATCH" src={mainmenuWatchImg} />
      <div>
        On the main menu, click "WATCH"
        </div>
      <br />
      <br />
    </div>
    <div className="instruction">
      <img title="click DOWNLOAD" alt="click DOWNLOAD" src={downloadSampleImg} />
      <div>
        Click the "DOWNLOAD" button. and then insert this id:
            <br />
        <br />
        <span className="downloadId">{props.watchId}</span>
      </div>
      <br />
      <br />
    </div>
    <div className="instruction">
      Enjoy the {props.enjoy}
    </div>
  </div>;
}
