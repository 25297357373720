
import React from 'react';
import { ColorSection } from '../../common/section/ColorSection';
import Color from 'color';
import { SectionContent } from '../../common/section/SectionContent';
import { DownloadButtons } from '../../common/download/DownloadButtons';
import "./FirstQuotesSection.css";

export function FirstQuotesSection(props: {color: Color}) {
    return <ColorSection color={props.color} >
        <SectionContent className="FirstQuotesSection">
            <div>
                <div className="quoteContainer firstQuoteContainer">
                    <span className="quoteText">"THE&nbsp;BEST&nbsp;GAME TO&nbsp;YOUR&nbsp;MOBILE"</span>
                    <span className="quoteAuthor">TOM BOGIN</span>
                </div>
                <div className="quoteContainer downloadQuoteContainer">
                    <span className="quoteText">
                        DOWNLOAD IT NOW<br />
                        SAVE 100%
                        <DownloadButtons />
                    </span>
                </div>
                <div className="quoteContainer thirdQuoteContainer">
                    <span className="quoteText">"WUF"</span>
                    <span className="quoteAuthor">Tom's Dog</span>
                </div>
                <div className="clear"></div>
            </div>
        </SectionContent>
    </ColorSection>
}