import axios from "axios";
export type AppCodeData = {
    gifterimg?: string;
    gifter: string;
}

export type AuthorData = {
    facebook_id?: string;
    author: string;
    score: number;
}

export function delay(delayMs: number) : Promise<void> {
    return new Promise((res) => {
        setTimeout(() => {
            res();
        }, delayMs);
    })
}

export async function getWatchData(id: string): Promise<AuthorData | undefined> {
    let result = await axios.get("/api/Watch/"+id);
    return result.data;
}

export async function getGiftData(id: string): Promise<AppCodeData | undefined> {
    let result = await axios.get("/api/GiftFetch/"+id);
    return result.data.gift;
}