
import React from 'react';
import { ColorSection } from '../../common/section/ColorSection';
import Color from 'color';
import { SectionContent } from '../../common/section/SectionContent';
import sunfuryImg from "./sunfury.png";
import facebookImg from "./facebook.png";
import twitterImg from "./twitter.png";
import "./FollowSection.css";

export function FollowSection(props: {color: Color}) {
    return <ColorSection color={props.color}>
        <SectionContent className="FollowSection" title={"FOLLOW & STAY TUNED"}>
            <div>
                <div className="imgContainer">
                    <a href="https://twitter.com/teamsunfury" target="_blank"> 
                        <img src={twitterImg} alt={"twitter"} />
                    </a>
                    {" "}
                    <a href="http://sunfury.team" target="_blank"> 
                        <img src={sunfuryImg} alt={"SUNFURY Website"} />
                    </a>
                    {" "}
                    <a href="https://www.facebook.com/TeamSunfury/" target="_blank"> 
                        <img src={facebookImg} alt={"facebook"} />
                    </a>
                </div>
                <div>
                    <div id="mc_embed_signup">
                        <div className="GetUpdatesText">Get updates about StairJump&nbsp;&&nbsp;SUNFURY:</div>
                        <form action="//tumblr.us13.list-manage.com/subscribe/post?u=df52f2c68c1e41efd1c0b8dfb&amp;id=b7062415cc" 
                                method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" 
                                className="validate" target="_blank" noValidate>
                            <label className="EmailLabel">
                                Email: 
                                <input type="email" name="EMAIL" className="email PurpleText" id="mce-EMAIL" placeholder="email address" required />
                            </label>
                            <div style={{position: "absolute", left: "-5000px"}} aria-hidden={true}>
                                <label>
                                    Hidden
                                    <input type="text" name="b_df52f2c68c1e41efd1c0b8dfb_b7062415cc" tabIndex={-1} />
                                </label>
                            </div>
                            <div className="clear">
                                <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
                            </div>
                        </form>
                    </div>


                </div>
            </div>
        </SectionContent>
    </ColorSection>
}